<template></template>

<script>
import { vuexOidcProcessSilentSignInCallback } from "vuex-oidc";
export default {
  name: "silentSignInCallback",
  data() {
    return {};
  },
  created() {
    vuexOidcProcessSilentSignInCallback();
  }
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
</style>